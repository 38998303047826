import React, { useState } from "react"
import { graphql } from "gatsby"

import Modal from "react-bootstrap/Modal"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import BtnLink from "../components/addon/Link"

const Academics = ({ data: { allNodeTemplatePages: { edges } } }) => {

  const [showModal, setShowModal] = useState(true)

  const showHomeModel = () => {
      setShowModal(true)
  }
  const closeModal = () => {
      setShowModal(false)
  } 

    const rawData = edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData?.node?.metatags_on_page || {}
    const data = rawData?.node?.relationships?.components;
    
    const bannerData = Array.isArray(data) && data.length > 0 && data[0];
    const bannerImg = typeof(bannerData?.relationships?.components?.[0]?.relationships?.bannerImage?.uri?.url) !== "undefined" ? bannerData?.relationships?.components?.[0]?.relationships?.bannerImage?.uri?.url : "/assets/images/patient_care/banner_patient_care.jpg"
    const mobileBannerImg = bannerData?.relationships?.components?.[0]?.relationships?.mobileBannerImage?.uri?.url || bannerImg;
    const bannerAlt = typeof(bannerData?.relationships?.components?.[0]?.bannerImage?.alt) !== "undefined" && bannerData?.relationships?.components?.[0]?.bannerImage?.alt.length > 0 ? bannerData?.relationships?.components?.[0]?.bannerImage?.alt : "Hinduja Hospital"
    const bannerTitle = typeof(bannerData?.relationships?.components?.[0]?.title) !== "undefined" && bannerData?.relationships?.components?.[0]?.title.length > 0 ? bannerData?.relationships?.components?.[0]?.title : "";
    const bannerSubtitle = typeof(bannerData?.relationships?.components?.[0]?.subtitle) !== "undefined" && bannerData?.relationships?.components?.[0]?.subtitle.length > 0 ? bannerData?.relationships?.components?.[0]?.subtitle : "";
    // For Doctor 
    const doctorData = Array.isArray(data) && data.length > 1 && data?.[1];
    const doctorSectionName = doctorData?.name;
    const [dnbCourses, masterCourses, certificateCourses, fellowships] = doctorData?.relationships?.components;
    // Doctor Tab1
    const dnbCoursesTabName = dnbCourses?.field_title;
    const dnbCoursesContent = dnbCourses?.relationships?.field_components?.[0]
    // Doctor Tab2
    const masterCoursesTabName = masterCourses?.field_title;
    const masterCoursesContent = masterCourses?.relationships?.field_components?.[0]
    const masterCoursesTitle = masterCoursesContent?.title;
    const masterCoursesTitle1 = masterCoursesContent?.title1;
    const masterCoursesImg = masterCoursesContent?.relationships?.image?.uri?.url
    const masterCoursesImgAlt = masterCoursesContent?.image?.alt;
    const masterCoursesDescription = masterCoursesContent?.description?.processed;
    // Doctor Tab3
    const certificateCoursesTabName = certificateCourses?.field_title;
    const certificateCoursesContent = certificateCourses?.relationships?.field_components?.[0]
    // Doctor Tab4
    const fellowshipsTabName = fellowships?.field_title;
    const fellowshipsContent = fellowships?.relationships?.field_components?.[0]
    // For Nurse
    const nurseData = Array.isArray(data) && data.length > 2 && data?.[2];
    const nurseSectionName = nurseData?.name;
    const nurseContent = nurseData?.relationships?.components?.[0];
    // Paramedical Courses
    const paramedicalCoursesData = Array.isArray(data) && data.length > 3 && data?.[3];
    const paramedicalCoursesSectionName = paramedicalCoursesData?.name;
    const paramedicalCoursesContent = paramedicalCoursesData?.relationships?.components?.[0];
    // Modal Data
    const allModalData = Array.isArray(data) && data.length > 4 && data?.[4]?.relationships?.components;

    
    const accordianComponent = data.filter( item => item?.__typename === "paragraph__accordian_component")
    console.log('abbbaba=====>',data)
    
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/academics.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage pt-first-section">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                          <img src={mobileBannerImg} alt={bannerAlt} className="d-md-none"/>
                          <img src={bannerImg} alt={bannerAlt} className="d-md-block d-none"/>
                        </picture>
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <h1>{bannerTitle}</h1>
                                <p>{bannerSubtitle}</p>
                            </div>
                        </div>
                    </section>
                    <section className="section_bg py-3 academicMainWrap">
                        <div className="academicCommonTab">
                            <div className="container">
                                {doctorSectionName 
                                ? <div className="bgPlusIcon">
                                    <div className="py-4">
                                        <h2>{doctorSectionName || ''}</h2>
                                    </div>
                                    <div className="aboutContainer">
                                        <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                                            {dnbCoursesTabName && <li role="presentation" className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#dnbc_ourses" role="tab" aria-controls="dnbc_ourses" aria-selected="true">{dnbCoursesTabName}</a> </li>}
                                            {masterCoursesTabName && <li role="presentation" className="nav-item"> <a className="nav-link" data-toggle="tab" href="#masters_courses" role="tab" aria-controls="masters_courses" aria-selected="false">{masterCoursesTabName}</a> </li>}
                                            {certificateCoursesTabName && <li role="presentation" className="nav-item"> <a className="nav-link" data-toggle="tab" href="#certificate_courses" role="tab" aria-controls="certificate_courses" aria-selected="false">{certificateCoursesTabName}</a> </li>}
                                            {fellowshipsTabName && <li role="presentation" className="nav-item"> <a className="nav-link" data-toggle="tab" href="#fellowships" role="tab" aria-controls="fellowships" aria-selected="false">{fellowshipsTabName}</a> </li>}
                                        </ul>
                                    </div>
                                    
                                    <div className="tab-content mt-3">
                                        {dnbCoursesContent?.text?.processed 
                                        ? <div className="tab-pane active" id="dnbc_ourses" role="tabpanel_dnbc_ourses" aria-labelledby="dnbc_ourses">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div dangerouslySetInnerHTML={{ __html: dnbCoursesContent?.text?.processed }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                        {masterCoursesContent && masterCoursesImg && masterCoursesTitle && masterCoursesDescription
                                        ? <div className="tab-pane" id="masters_courses" role="tabpanel" aria-labelledby="masters_courses">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="academicBannerWrap">
                                                            <span className="heroImage">
                                                                <img src={masterCoursesImg} alt={masterCoursesImgAlt} />
                                                                {/* <img src="assets/images/academics/banner_doctors_mastercourse.png" alt="Doctors Banner" /> */}
                                                            </span>
                                                            <div className="captionText">
                                                                <h3>{masterCoursesTitle}</h3>
                                                                <span className="subText">{masterCoursesTitle1}</span>
                                                                <BtnLink data={masterCoursesContent} classes="btn btn-primary" />
                                                            </div>  
                                                        </div>
                                                        <div className="cardInnerWrap">
                                                            <div className="para_text">
                                                                <div dangerouslySetInnerHTML={{ __html: masterCoursesDescription }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                        
                                        {certificateCoursesContent?.text?.processed 
                                        ? <div className="tab-pane" id="certificate_courses" role="tabpanel_certificate_courses" aria-labelledby="certificate_courses">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div dangerouslySetInnerHTML={{ __html: certificateCoursesContent?.text?.processed }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        : null}
                                        {fellowshipsContent?.text?.processed 
                                        ? <div className="tab-pane" id="fellowships" role="tabpanel" aria-labelledby="fellowships">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div dangerouslySetInnerHTML={{ __html: fellowshipsContent?.text?.processed }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                        
                                    </div>
                                </div> 
                                : null} 
                            </div>
                        </div>

                        <div className="academicCommonTab">
                            <div className="container">
                                {nurseSectionName && nurseContent?.text?.processed 
                                ?  <div className="py-4">
                                    <h2>{nurseSectionName || ''}</h2>
                                    {nurseContent?.text?.processed 
                                    ? <div dangerouslySetInnerHTML={{ __html: nurseContent?.text?.processed }} />
                                    : null}
                                </div> 
                                : null}
                                {paramedicalCoursesSectionName && paramedicalCoursesContent?.field_title && paramedicalCoursesContent?.text?.processed 
                                ? <div className="">
                                    <div className="pb-4">
                                        <h2>{paramedicalCoursesSectionName}</h2>
                                    </div>
                                    <div className="aboutContainer">
                                        <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                                            <li role="presentation" className="nav-item"> 
                                                <a className="nav-link active" data-toggle="tab" href="#technical_courses_nurse" role="tab" aria-controls="technical_courses_nurse" aria-selected="true">
                                                    {paramedicalCoursesContent?.field_title}
                                                </a> 
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content mt-3">
                                        <div className="tab-pane active" id="technical_courses_nurse" role="tabpanel" aria-labelledby="technical_courses_nurse">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div dangerouslySetInnerHTML={{ __html: paramedicalCoursesContent?.text?.processed }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            </div>
                        </div>
                    </section>
                    {
                      accordianComponent && accordianComponent[0]?.relationships && accordianComponent[0]?.relationships?.field_component_items?.length > 0 &&
                      <div className="academicMainWrap pt-5 pb-5">
                        <div className="container">
                          <h2>{accordianComponent[0]?.field_title || ''}</h2>
                          <div
                            id="accordion"
                            role="tablist"
                            className="common_accordion specialty_accordion mt-4"
                          >
                            { accordianComponent[0]?.relationships?.field_component_items?.map((item, index) => {
                                return (
                                  <div className="card" key={index}>
                                    <div
                                      className="card-header"
                                      role="tab"
                                      id={`heading_inpatient0${index + 1}`}
                                    >
                                      <h5 className="mb-0">
                                        <a
                                          className="collapsed"
                                          data-toggle="collapse"
                                          href={`#outpatient_0${index + 1}`}
                                          aria-expanded="false"
                                          id={index + 1}
                                        >
                                          {item?.field_title_1}
                                        </a>
                                      </h5>
                                    </div>
                                    <div
                                      id={`outpatient_0${index + 1}`}
                                      className="collapse"
                                      role="tabpanel"
                                      aria-labelledby="heading_inpatient01"
                                      data-parent="#accordion"
                                    >
                                      <div className="card-body">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item?.field_description?.value,
                                            }}
                                          />
                                      </div>
                                    </div>
                                  </div>
                                )
                            })}
                          </div>
                        </div>
                      </div>
                    }

                  <Modal
                    show={showModal}
                    animation={false}
                    centered
                    size="lg"
                    dialogClassName="common_modal homepageModel mBodyPop"
                  >
                    <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">X</span>
                    </button>
                    {/* <div class="modal-header">
                        <h2>Kind Attention:</h2>
                    </div> */}
                    <Modal.Body>
                    <img className="lazyload" src="/assets/images/pdfImgFor_Popup.png" data-src="/assets/images/pdfImgFor_Popup.png" alt="PDF image" />                    
                    </Modal.Body>
                  </Modal>
                </main>
                {allModalData?.length 
                ? allModalData.map((modal, i) => {
                    return (
                        <div className="modal common_modal fade pay_modal modal-center academic-modal" key={modal?.field_modal_id} id={modal?.field_modal_id || ''} tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {modal?.field_title &&
                                        <div className="modal-header">
                                            <h2>{modal?.field_title}</h2>
                                        </div>
                                    }
                                    <div className="modal-body">
                                        <div className="pay_terms_wrap">
                                            <div dangerouslySetInnerHTML={{ __html: modal?.field_description?.processed }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) 
                : null}
            </Layout>
        </>
    )
}

export const query = graphql`
query academics {
  allNodeTemplatePages(filter: {path: {alias: {regex: "/academics$/"}}}) {
    edges {
      node {
        id
        title
        path {
          alias
        }
        relationships {
          field_seo_schema {
            field_seo_title
            field_seo_schema_text_area
          }
        }
        metatag_normalized {
          attributes {
            content
            name
            property
          }
        }
        metatags_on_page: field_meta_tags {
          canonical_url
        }
        relationships {
          components: field_component_type {
            __typename
            ...acaParagraphSection
            ...acaAccordion
          }
        }
      }
    }
  }
}

fragment acaParagraphSection on paragraph__section {
  id
  name: field_name
  relationships {
    components: field_components {
      __typename
      ...acaParagraphBanner
      ...acaParagraphTitleAndDescription
      ... on paragraph__title_and_description_components {
        id
        field_name
        field_title
        field_subtitle
        field_link {
          uri
          title
        }
        relationships {
          field_components {
            ...acaParagraphTitleAndDescription
            ...acaParagraphImageTitleDescLink
          }
        }
      }
      ... on paragraph__modal {
        id
        field_title
        field_modal_id
        field_description {
          processed
        }
      }
    }
  }
}

fragment acaParagraphBanner on paragraph__banner {
  id
  title: field_title
  subtitle: field_subtitle
  bannerImage: field_banner_image {
    alt
  }
  mobileBannerImage: field_mobile_banner {
    alt
  }
  bannerLink: field_banner_link {
    uri
    title
  }
  relationships {
    bannerImage: field_banner_image {
      id
      uri {
        value
        url
      }
    }
    mobileBannerImage: field_mobile_banner {
      id
      uri {
        url
        value
      }
    }
  }
}

fragment acaParagraphTitleAndDescription on paragraph__title_and_description {
  id
  field_title
  text: field_description {
    processed
  }
  field_cta_link {
    title
    uri
  }
  field_text {
    processed
  }
}

fragment acaParagraphImageTitleDescLink on paragraph__image_title_desc_link {
  id
  title: field_title
  title1: field_title_1
  description: field_description {
    processed
  }
  link: field_link {
    uri
    title
  }
  image: field_image {
    alt
  }
  relationships {
    image: field_image {
      id
      uri {
        value
        url
      }
    }
  }
}

fragment acaAccordion on paragraph__accordian_component {
  id
  field_title
  relationships {
    field_component_items {
      field_title_1
      field_description {
        value
      }
    }
  }
}
`
export default Academics